import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import Social from "../components/social"

const ListLink = props => (
  <li>
    <Link activeClassName='active' to={props.to}>{props.children}</Link>
  </li>
)

export default function Layout({ children }) {
  return (
    <div class='layout'>
      <Helmet>
        <title>EtherGrass</title>
      </Helmet>
      <header>
        <a class='logo' href="#">
          <img src='logo.png'/>
        </a>
        <ul class='nav-links'>
          <ListLink to="#home">HOME</ListLink>
          <ListLink to="#mint">MINT</ListLink>
          <ListLink to="#wagmi">$WAGMI TOKEN</ListLink>
          <ListLink to="#find">FIND US</ListLink>
        </ul>
        
      </header>
      {children}
        <footer>
          {/* <Social/> */}
        </footer>
    </div>
  )
}