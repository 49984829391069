import React from "react"
import { useState, useEffect } from 'react';
import { Link } from "gatsby"
import Loader from 'react-loader-spinner';
import { ChainId, DAppProvider, useEtherBalance, useEthers, useContractFunction, useContractCall } from '@usedapp/core'
import { formatEther } from '@ethersproject/units'
import { utils } from 'ethers'
import { Contract } from '@ethersproject/contracts'
import { getContractData, getContractAddress, useSimpleContractCall } from "../utils/contract.js"

export default function MintDapp(props) {

  const MintLoadingIndicator = props => {
    return (
      <div style={{
          marginTop: "20px",
          width: "100%",
          height: "40",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}>
        <Loader type="BallTriangle" color="#44615a" height="40" width="40" />
      </div>
    );  
  }

  const { activateBrowserWallet, account } = useEthers()

  const egInterface = new utils.Interface(getContractData().abi)
  const egContractAddress = getContractAddress()
  const egContract = new Contract(egContractAddress, egInterface)

  const egsFreeMintsAllowed = useSimpleContractCall(egContract, 'etherGrassMintingAllowed')
  const egsPublicMintsAllowed = useSimpleContractCall(egContract, 'publicMintingAllowed')
  
  const egsMinted = useSimpleContractCall(egContract, 'totalMint')
  const egsTotal = useSimpleContractCall(egContract, 'MAX_ELEMENTS')
  const egsSaleClosed = useSimpleContractCall(egContract, 'paused')
  const egsPartnerNfts = useSimpleContractCall(egContract, 'etherGrassIdsOwned', account)
  const egsPartnerMintsAvailable = useSimpleContractCall(egContract, 'etherGrassMintsClaimable', account)

  const { state:mintState, send:mintSend } = useContractFunction(egContract, 'mint', { transactionName: 'Mint' })
  const { state:mintStateFree, send:mintSendFree } = useContractFunction(egContract, 'etherGrassMint', { transactionName: 'Free Mint' })

  const [lastTerminalMintState, setLastTerminalMintState] = useState(null);

  const [connectDelaying, setConnectDelaying] = useState(true);
  const [bannerOpen, setBannerOpen] = useState(0);
  const [bannerContent, setBannerContent] = useState(0);
  const [userMintCount, setUserMintCount] = useState(1);
  const [totalMintCount, setTotalMintCount] = useState('?');
  const [totalMaxMintCount, setTotalMaxMintCount] = useState('?');
  const [saleClosed, setSaleClosed] = useState(true);
  const [partnerMintsAllowed, setPartnerMintsAllowed] = useState(false);
  const [publicMintsAllowed, setPublicMintsAllowed] = useState(false);
  const [partnerMintCount, setPartnerMintCount] = useState(1);
  const [partnerMintOpen, setPartnerMintOpen] = useState(false);
  const [partnerNfts, setPartnerNfts] = useState([]);
  const [partnerMintsAvailable, setPartnerMintsAvailable] = useState(0);

  setTimeout(() => {
    setConnectDelaying(false)
  }, 300);

  const onWalletActivationError = (error) => {
    console.log(error.message)
    setBannerContent(error.message)
    setBannerOpen(true)
  }

  const attemptToMint = () => {
    if (mintState && mintState.status == "Mining") {
      setBannerContent("Mint Already In Progress!")
      setBannerOpen(true)
      return
    }
    else if (userMintCount > 10 || userMintCount < 1) {
      setBannerContent("Only 1-10 NFTs per Transaction")
      setBannerOpen(true)
      return
    }
    setBannerOpen(false)
    console.log("Minting!!!")
    mintSend(account, userMintCount, { value: utils.parseEther("0") })
  }

  const attemptToFreeMint = (partnerNftIds) => {
    if (mintStateFree && mintStateFree.status == "Mining") {
      setBannerContent("Mint Already In Progress!")
      setBannerOpen(true)
      return
    }
    setBannerOpen(false)
    console.log("Minting!!!")

    let partnerNftStringIds = partnerNftIds.map((bigInt) => {
      return utils.formatUnits(bigInt, 0)
    })

    console.log(partnerNftStringIds)

    mintSendFree(account, partnerMintCount, partnerNftStringIds, { value: utils.parseEther("0") })
  }

  const onMintSuccess = () => {
    if (lastTerminalMintState != 'Success') {
      setBannerContent("Mint Successful!")
      setBannerOpen(true)
      setLastTerminalMintState('Success')
    }
  }

  const onMintFail = (error) => {
    if (lastTerminalMintState != error) {
      setBannerContent("Mint Failed: "+error)
      setBannerOpen(true)
      setLastTerminalMintState(error)
    }
  }

  const showPartnerMint = () => {
    setPartnerMintOpen(true)
  }

  const hidePartnerMint = () => {
    setPartnerMintOpen(false)
  }

  
  

  useEffect(() => {
    if (mintState && mintState.status == 'Success') {
      console.log(mintState.transaction)
      onMintSuccess()
    }
    else if (mintState && (mintState.status == 'Fail' || mintState.status == 'Exception')) {
      console.log(mintState.transaction)
      onMintFail(mintState.errorMessage || "Unknown Error")
    }

    if (mintStateFree && mintStateFree.status == 'Success') {
      console.log(mintStateFree.transaction)
      onMintSuccess()
    }
    else if (mintStateFree && (mintStateFree.status == 'Fail' || mintStateFree.status == 'Exception')) {
      console.log(mintStateFree.transaction)
      onMintFail(mintStateFree.errorMessage || "Unknown Error")
    }

    if (egsMinted) {
      console.log(egsMinted)
      setTotalMintCount(parseInt(utils.formatUnits(egsMinted, 0)))
    }

    if (egsTotal) {
      console.log(egsTotal)
      setTotalMaxMintCount(parseInt(utils.formatUnits(egsTotal, 0)))
    }

    if (egsSaleClosed != null) {
      console.log(egsSaleClosed)
      setSaleClosed(egsSaleClosed)
    }

    if (egsFreeMintsAllowed != null) {
      console.log(egsFreeMintsAllowed)
      setPartnerMintsAllowed(egsFreeMintsAllowed)
    }

    if (egsPublicMintsAllowed != null) {
      console.log(egsPublicMintsAllowed)
      setPublicMintsAllowed(egsPublicMintsAllowed)
    }

    if (egsPartnerNfts != null) {
      console.log(egsPartnerNfts)
      setPartnerNfts(egsPartnerNfts)
    }

    if (egsPartnerMintsAvailable != null) {
      console.log(egsPartnerMintsAvailable)
      setPartnerMintsAvailable(parseInt(utils.formatUnits(egsPartnerMintsAvailable, 0)))
    }

  }, [mintState, mintStateFree, egsMinted, egsTotal, egsSaleClosed, egsPartnerNfts, egsPartnerMintsAvailable])
  


  return (
    
    <div class="dapp">

      {!account && 
      <div class={bannerOpen ? "open banner-container" : "closed banner-container"}>
        <div class="banner-padding">
          <div class="banner-close-button" role="button" tabIndex={0} aria-label="close" onKeyDown={() => setBannerOpen(false)} onClick={() => setBannerOpen(false)}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z"/></svg></div>
          <div class="banner-content">{bannerContent}</div>
        </div>
      </div>}
        
      {!account && 
      <div class={(!account && !connectDelaying) ? "connect-container open" : "connect-container"}>
        <p class="connect-text">While using a browser-based wallet (ie: MetaMask), click "Connect Wallet" below to prepare to mint!</p>
        <div class={account && "button disabled" || "button"} role="button" disabled={account} onClick={() => activateBrowserWallet(onWalletActivationError)} onKeyDown={() => activateBrowserWallet(onWalletActivationError)}>
          <div class="button-text">{(account && "Connected!") || "Connect Wallet"}</div>
        </div>
      </div>}

      <div class={account ? "did-connect-container open" : "did-connect-container"}>
        <p class="wallet-text">Your Address is:<br/><span class="wallet-text-sm">{account}</span></p>
        <p class="wallet-text">X-B31 NFTs are FREE!<br/><span class="wallet-text-sm">(just pay gas)</span><br/><br/>1-10 NFTs Per Transaction</p>
        <p class="wallet-text"><b>Good Luck!</b></p>

        <hr/>

        <div class={bannerOpen ? "open banner-container" : "closed banner-container"}>
          <div class="banner-padding">
            <div class="banner-close-button" role="button" tabIndex={0} aria-label="close" onKeyDown={() => setBannerOpen(false)} onClick={() => setBannerOpen(false)}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z"/></svg></div>
            <div class="banner-content">{bannerContent}</div>
          </div>
        </div>

        <div class="nfts-available">{totalMintCount} / {totalMaxMintCount}</div>
        <div class="nfts-available-text">Total X-B31s Minted</div>

        {!saleClosed && publicMintsAllowed && (totalMintCount < totalMaxMintCount) && !partnerMintOpen &&
        <div class="sale-open">
          <div class="mint-count">
            <label for="user-mint-count"> How many do you want (1-10)?
              <span class="mint-count-container"><input id="user-mint-count" type="number" value={userMintCount} onChange={(event) => setUserMintCount(event.target.value)}/></span>
            </label>
          </div>
          <div class={mintState && mintState.status == "Mining" && "button disabled" || "button"} role="button" disabled={mintState && mintState.status == "Mining"} onClick={() => attemptToMint()} onKeyDown={() => attemptToMint()}>
            <div class="button-text">{(mintState && mintState.status == "Mining" && "Minting...") || "Mint"}</div>
          </div>
          {mintState && mintState.status == "Mining" && <MintLoadingIndicator/>}
        </div>}

        {partnerMintsAllowed && (totalMintCount < totalMaxMintCount) && partnerMintOpen && partnerNfts &&
        <div class="sale-open">

          {partnerNfts.length <= 0 && 
            <div>Sorry.<br/>Your wallet does not contain any EtherGrass.</div>
          }

          {partnerNfts.length > 0 && 
          <div>
            <div class="mint-free-text">
              <div class="mint-free-title">You're Holding <b>{partnerNfts.length}</b> EtherGrass<br/>and Have <b>{partnerMintsAvailable}</b> Free Mints!</div>
              <div>---</div>
              <div>Choose how many you would like now, and tap the button below to mint your free X-B31s.</div>
            </div>
            <div class="mint-count">
              <label for="user-mint-count"> How many do you want to mint now?
                <span class="mint-count-container"><input id="user-mint-count" type="number" value={partnerMintCount} onChange={(event) => setPartnerMintCount(event.target.value)}/></span>
              </label>
            </div>
            <div class={mintStateFree && mintStateFree.status == "Mining" && "button disabled" || "button"} role="button" disabled={mintStateFree && mintStateFree.status == "Mining"} onClick={() => attemptToFreeMint(partnerNfts)} onKeyDown={() => attemptToFreeMint(partnerNfts)}>
              <div class="button-text">{(mintStateFree && mintStateFree.status == "Mining" && "Minting...") || "Free Mint"}</div>
            </div>
            {mintStateFree && mintStateFree.status == "Mining" && <MintLoadingIndicator/>}
          </div>}

        </div>}

        {(saleClosed || !publicMintsAllowed) && (totalMintCount < totalMaxMintCount) && !partnerMintOpen &&
        <div class="sale-closed">
          <div class="sale-closed-title">The Public Sale<br/>is Currently Closed</div>
          <div class="sale-closed-text">When minting opens, this page will automatically refresh</div>
        </div>}

        {(saleClosed || !partnerMintsAllowed) && (totalMintCount < totalMaxMintCount) && partnerMintOpen &&
        <div class="sale-closed">
          <div class="sale-closed-title">The EtherGrass Pre-Sale<br/>is Currently Closed</div>
          <div class="sale-closed-text">When minting opens, this page will automatically refresh</div>
        </div>}

        {(totalMintCount >= totalMaxMintCount && totalMintCount != '?') && 
        <div class="sale-closed">
          <div class="sale-closed-title">Sold Out!</div>
          <div class="sale-closed-text">Thank you for your support!</div>
        </div>}

        {(totalMintCount == '?' && totalMaxMintCount == '?') && 
        <div class="sale-closed">
          <div class="sale-closed-title">Disconnected</div>
          <div class="sale-closed-text">Waiting for connection to chain</div>
        </div>}

        {(totalMintCount < totalMaxMintCount) && !partnerMintOpen &&
          <div class="partner-button-container">
            <div class="partner-button" role="button" onClick={() => showPartnerMint()} onKeyDown={() => showPartnerMint()}>Own EtherGrass?</div>
          </div>
        }

        {(totalMintCount < totalMaxMintCount) && partnerMintOpen &&
          <div class="partner-button-container">
            <div class="partner-button" role="button" onClick={() => hidePartnerMint()} onKeyDown={() => hidePartnerMint()}>&lt;&nbsp;Back</div>
          </div>
        }

      </div>

    </div>
  )
}
