import React from "react"
import { Link } from "gatsby"
import { ChainId, DAppProvider, useEtherBalance, useEthers } from '@usedapp/core'
import { usePromiseTracker, trackPromise } from "react-promise-tracker"
import Loader from 'react-loader-spinner';
import Layout from "../components/layout"
import Api from "../utils/api.js"
import MintDapp from "../components/mint_dapp"

const api = new Api()

const dAppConfig = {
}

const bannerMsgs = {
  "mintSuccess": {
    "header": "Mint Successful!",
    "content": "We hope you like it!"
  },
  "mintFail": {
    "header": "Mint Failed!",
    "content": "It may have been cancelled or the mint has sold out."
  },
  "generalError": {
    "header": "Oh No!",
    "content": "An error occurred!"
  }
}

export default class Home extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
    this.reload = this.reload.bind(this);
  }

  componentDidMount() {
  }

  reload() {
  }

  render() { return (
    <Layout>

      <div class="section-1" id="home">
        <div class="col-1">
          <img src="/images/10mints.png"/>
          <p class="home-description">The year is 4269. Humans are slaves to a race of Artificial Intelligence. They live on the Planet X-B31. The humans main purpose on this pure rock and metal planet is to mine precious metals for the AI super race. The rarest item a human can possess is a single blade of grass. If they are able to find 2 blades of grass, they can buy their freedom.</p>
        </div>
        <div class="col-2">
          <img src="/images/floatyStuff.png"/>
        </div>
        <img class="banner-grass distraction" src="/images/bannerGrass.png"/>
        <img class="cube-1 distraction" src="/images/cube.png"/>
        <img class="cube-2 distraction" src="/images/cube.png"/>
        <img class="cube-3 distraction" src="/images/cube.png"/>
      </div>

      <div class="section-header" id="mint">
        <p>Mint</p>
      </div>

      <div class="section-2">
        <div class="col-1">
          <div class="pfp-free">
            <p>X-B31 is now a <b>FREE MINT</b> until sold&nbsp;out!<br/>Only 2222 total available!</p>
          </div>
          <DAppProvider config={dAppConfig}>
            <MintDapp />
          </DAppProvider>
        </div>
        <div class="col-2">
          <img src="/images/mintArt.png"/>
        </div>
        <img class="wagmi-grass" src="/images/footGrass.png"/>
        <img class="double-grass distraction super-distraction" src="/images/twoGrass.png"/>
      </div>

      <div class="section-3" id="wagmi">
        <div class="col-1">
          <img src="/images/wagmiToken.png"/>
          <p>WAGMI is the EtherGrass community token and organically supports our eco-system. It is a tipping utility that employs the popular "WAGMI" Internet meme, and features a unique logo. A verified smart contract token, based on the Binance Smart Chain (BSC), WAGMI is utilized for community rewards, NFT trades across markets and technological advancement. WAGMI's token contract was created on November 3, 2021, with a capped supply of 31 million tokens. You can buy or sell WAGMI via PancakeSwap on BSC. More information about WAGMI token is available at <a class="wagmi-link" href="https://www.wagmi-coin.com">www.wagmi-coin.com</a></p>
          <img src="/images/hands.png"/>
        </div>
        <div class="col-2">
          <img src="/images/bricks.png"/>
        </div>
        <img class="wagmi-grass" src="/images/wGrass.png"/>
        <img class="double-grass-h distraction super-distraction" src="/images/twoGrassH.png"/>
      </div>

      <div class="section-4" id="find">
        <h2>Find us</h2>
        <div class="social">
          <a href="https://discord.gg/AkeXbF8bvq">
            <img src="/images/footerD.png"></img>
          </a>
          <a href="https://twitter.com/ethergrass">
            <img src="/images/footerT.png"></img>
          </a>
          <a href="https://instagram.com/ethergrass">
            <img src="/images/footerI.png"></img>
          </a>
        </div>
        <p>©2022</p>
        <img class="footer-grass" src="/images/footGrass.png"/>
      </div>

    </Layout>
  )}
}
